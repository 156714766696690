html,
body {
  width: 100%;
  height: 100%;
  background-color: #efefef;
}

.App {
  height: 100%;
}

.LeftDrawer:hover {
  text-decoration: none;
  background-color: rgb(137, 137, 163) !important;
  color: #ffffff;
  border-radius: 5px;
}
.LeftDrawer.Mui-selected {
  background-color: #ffffff !important;
  border-radius: 5px;
  min-height: 51px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 로딩 모달 */
.modal-loading {
  width: 100%;
  height: 100vh;
  background-color: #000;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1038;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.load-cont {
  margin-top: -100px;
}

.spinner-wrap {
  display: flex;
  justify-content: center;
  height: 65px;
}
.spinner {
  width: 12px;
  height: 12px;
  animation: spinner-o824ag 1s infinite linear;
}
.spinner div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ff6c58;
  border-radius: 50%;
  animation: spinner-vse6n7 1.25s infinite ease;
}
.spinner div:nth-child(1) {
  --rotation: 90;
}
.spinner div:nth-child(2) {
  --rotation: 180;
}
.spinner div:nth-child(3) {
  --rotation: 270;
}
.spinner div:nth-child(4) {
  --rotation: 360;
}
@keyframes spinner-vse6n7 {
  0%,
  100% {
    transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
  }

  50% {
    transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
  }
}

@keyframes spinner-o824ag {
  to {
    transform: rotate(360deg);
  }
}

.datatable-mng-tenants {
  height: 631px;
}

.tenants-list-pagenation {
  display: flex;
  width: 100%;
  justify-content: center;
}

.left-menu-root {
  width: 100%;
  max-width: 205px;
  height: 100vh;
  background-color: rgb(18, 17, 66);
  color: snow;
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  padding-right: 12px;
}

.left-user-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 8px;
  padding-right: 8px;
}

.left-list-item {
  margin: 0 0 2px;
}

.left-icon-btn {
  min-width: 40px !important;
}

.left-icons {
  color: rgba(255, 255, 255, 0.25);
}

.left-footer-menu {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 16px;
}

.btn-left-sign-out {
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: snow;
}

.my-account-header-root {
  height: 5rem;
  min-height: 5rem;
  display: flex;
  justify-content: space-between;
  padding-left: 48px;
  padding-right: 48px;
  align-items: center;
}

.my-account-header-title {
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgb(3, 0, 128);
}

.my-account-content-sub-title {
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: rgba(0, 0, 0, 0.87);
}

.my-account-content-root {
  overflow: auto;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 64px;
  height: 100%;
}

.apikey-content-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 40px;
  padding-bottom: 16px;
}

.data-table {
  min-width: 650px;
}

.data-table-header {
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: rgba(0, 0, 0, 0.6);
}

.data-table-img-button {
  color: rgba(100, 100, 255, 0.8);
}

.color-red {
  color: red;
}

.my-account-setting-detail-row {
  display: flex;
  flex-direction: row;
}

.setting-detail-sub-title {
  min-width: 150px;
  color: rgba(0, 0, 0, 0.5);
}

.w-20 {
  width: 20px;
}

.main-video {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background: no-repeat center center;
  background-size: cover;
}

.main-copy {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ta-center {
  text-align: center;
}

.roboto {
  font-family: "Roboto", sans-serif;
}
.fw-300 {
  font-weight: 400;
}

.fw-700 {
  font-weight: 800;
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.color-white {
  color: #ffffff;
}

.fs-big {
  font-size: 3.5rem;
}

.fs-normal {
  font-size: 1.3rem;
}

.ml-0 {
  margin-left: 0;
}

.info-main-header-root {
  height: 5rem;
  min-height: 5rem;
  display: flex;
  justify-content: space-between;
  padding-left: 48px;
  padding-right: 48px;
  align-items: center;
}

.info-main-header-title {
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgb(3, 0, 128);
}

.info-main-content-root {
  overflow: auto;
  padding-left: 48px;
  padding-right: 48px;
  padding-bottom: 64px;
  height: 100%;
}

.info-main-content-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 40px;
  padding-bottom: 16px;
}

.info-main-content-title {
  font-size: 1.2rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: rgba(0, 0, 0, 0.87);
}

.info-main-content {
  display: flex;
  align-items: flex-start;
  padding-top: 40px;
  padding-bottom: 16px;
}

.info-main-content-sub-root {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.info-main-content-sub-title {
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  line-height: 1.5 !important;
  color: rgb(3, 0, 128);
}

.info-main-request-body-title {
  font-size: 1rem !important;
  font-weight: bold !important;
  line-height: 1.4 !important;
}

.info-main-request-body-text {
  font-size: 1rem !important;
  line-height: 1.4 !important;
}

.info-main-request-body-text-sub {
  font-size: 1rem !important;
  line-height: 1.4 !important;
  padding-left: 20px;
}

.vector-document-title {
  font-size: 1.5rem !important;
  font-weight: 700 !important;
  line-height: 1.5 !important;
  color: rgb(47, 96, 164);
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.width-100 {
  width: 100%;
}
